import * as React from "react";
import {
  Wrapper,
  LegalMentionsWrapper,
  RowWrapper,
} from "./GraffitiHistoire.module.css";
import { GrayTitle } from "../../../../css/Title.module.css";
import banner1 from "../../../../res/histoire/post-graff-1.png"
import banner2 from "../../../../res/histoire/post-graff-2.png"
import banner3 from "../../../../res/histoire/post-graff-3.png"

const HistoireGraffiti = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle} >
        FROM GRAFFITI TO POST-GRAFFITI
      </h1>
      <div  style={{alignItems: "center",textAlign: "center", paddingTop: "10px"}}>
              <img style={{width: "100%"}} src={banner1} alt=""/>
            </div>
      <div className={LegalMentionsWrapper}>
        <div className={RowWrapper}>
          <div>
            <p style={{fontWeight:"bolder"}}>Révolution Artistique</p>
            <p>Synonym of vandalism for some, a form of visual culture legitimacy and claimed appropriation of space for others, the New-York graffiti of the 1970s-1980s and its different styles do not let anyone unmoved.</p>
            <br/><br/>
            <p style={{fontWeight:"bolder"}}>« Taki 183 Spawns Pen Pals » July 21st. New York Times</p>
            <p>We are in July 1971. An article from the NY Times is making a sensation as well on Manhattan island as in the most disadvantaged areas of the city.</p>
            <br />
            <p>During the year 1971, the tag was everywhere on the New-York city streets. Graffiti artists began to refine their style, following a learning system extremely hierarchical that allowed the novices to approach the pioneers and founders of the movement. The learning system follows precise rules: the masters could either criticize the beginners' creations or give them work to do (tags to recopy in their black books) and even invite them - if their initiatory journey was successful - to realize a four-hand small or large art piece. Then, the young graffiti artist would integrate the crew. It is up to him to prove himself by improving: his style (and the group style), and his technics while achieving a better mastery of the spray bomb (and while getting acquainted with the color palette of the aerosol manufacturer): without forgetting to learn the urban network of the MTA, the location of the various train depots and construction sites in progress... a true quest with its codes and laws, joys and pains, risks and adrenaline rushes, meetings and hates. A world in the city written by children and pre and post teenagers. With an ultimate goal: the recognition of his « family » and his peers as the king of a line: the « King of style ». To be seen by the greatest number: getting out of the anonymity « anonymously »... a society within the society...</p>
            <br/><br/>
            <p style={{fontWeight:"bolder"}}>Fondation. Affirmation.</p>
            <p style={{fontStyle: "italic"}}>« These young people who found in the graffiti movement an outlet to their desires of identity and recognition, are not ready to write it off. »</p>
            <br />
            <p>The foundation of the graffiti: the identity.</p>
            <p>The tag: a way to affirm freely, authentically, and visually outside of any constraint.</p>

            <br />
            <p>Between 1972 and 1973, the search for styles, to stand out from the growing number of writers, was in full swing. Despite the first anti-graffiti laws of 1972, the phenomenon has become more pronounced. The simplicity of the signature of the beginning faces a more elaborate work, giving birth to identifiable styles and masters of style. Crosses, stars, and spirals appear as a filling pattern within the graffs (Phase 2 developed his letter of the type Bubble and excel in the Wild Style, a « savage » style, unreadable for the non-initiate ones where intertwine letters created in 1974 by Tracy 168. He went as far as scrambling the readability of his name, the essence of graffiti.) Around the letters, the backgrounds appear. Letters gain in volume with graffs that add a 3D effect. (Ali from the Soul Artists group). But the virtuosity of the writers is not limited to the elaboration of the lettering. It also invests in the illustration field, assimilating and/or modifying the references of the mass culture (cartoons, comics, cinema...).</p>
            <br/>
            <p>The hits (tags, signatures) until then limited to the subway station and inside the cars, become more and more readable and appropriate to the exterior of the cars. Thanks to the utilization of the fat cap, the walls of the trains are covered by larger and larger, and more and more complex pieces (graff, large and outlined lettering). This cohesion, this affirmation, and this illegal shell made the graffiti artists fascinating and attractive in the eyes of the artistic sphere of NYC because their qualities were indicative of the exclusion of a new artistic avant-garde.</p>
            <br />
            <p>Claes Oldenburg, 1973 « You are here, in a station, everything is gray and gloomy, and suddenly, one of this colored and tagged subway train arrives and illuminates the place like a bunch of Latin America. »</p>
            <br />
            <p>Sign of the magnitude that takes the writing at the beginning of the 1970s, some cultural observers began to take an interest in this new form of art, invented and created in the ghettos, outside the conventional art.</p>
            <br />
            <p>The art critic R. Goldstein wrote in March 1973 an article in the New York Magazine entitled The Graffiti Hit Parade « The essential in the graffiti is not the force of destruction, but of cohesion, its capacity to meet a whole generation of children from the popular classes in an experience both positive and delinquent. »</p>
            <br />
            <p>Hugo Martinez, a student in sociology, has founded a collective called UGA, aware of the artistic potential of this first generation of artists, and had regrouped in a studio with some writers like Phase 2, or StayHigh 149. From September of 1973, the Razor Gallery in the Soho district, 464 West Broadway, welcomed the group and opened its doors to the public. The new artistic language has just entered a gallery... a half-century already!... Thus, it sweeps conventions, rules, and artistic canons of the time with exuberance and humor... This art, we don’t question what it wants, why it is like it is... Beauce it IS, simply, looking at the world through windows very different from those previously allowed.</p>
            <br />
            <p>The New York Times critic, Peter Schjeldahl wrote about this exhibition: « the canvases were missing a structure but presented an amazing strength in the utilization of colors. This work (with a spray bomb) on a canvas was resuming the exuberant insolence of tags seen across the city on subways and walls. The efforts of the ghetto children were printed with indomitable volcanic energy. These young who found in the graffiti movement an outlet for their desires for identity and recognition are not ready to write it off. »</p>

            <br />
            <div  style={{alignItems: "center",textAlign: "center", paddingTop: "10px"}}>
              <img style={{width: "100%"}} src={banner2} alt=""/>
            </div>
            <br/>
            <p style={{fontWeight:"bolder"}}>« A new deposit »: the name is the faith of graffiti</p>
            <p style={{fontStyle: "italic"}}>The world of galleries, collectors, and critics, was not looking different from the world of graffiti: « it was a new deposit, a new playground in which graffiti artists had to start from 0 and groove themselves. » Dondi White.</p>
            <br />
            <p>Every artist in the subway was not aspiring to be exhibited in a gallery. The ones who wished to, the ones who had the opportunity or the talent to integrate the ‘official’ world of art have taken different creative directions but complementary to the original culture of graffiti.</p>
            <br />
            <p>There were artists who, on canvas, repeated their tags (like Seen, Zephyr, or Blade) and the ones who were exploring new themes (like Dondi White, Lee Quinones, Futura 2000, or Daze): there were the ones who went to exhibit in Europe from the end of the 1970s (Medusa gallery, Rome, 1979) and the ones who went later. The graffiti artists of that time knew that the world of art considered them with fascination and suspicion without being aware of what graffiti culture was. It is, partly, thanks to this perception of these artists that the transition from the street to the canvas has been well accepted at the end of the 1970s. Some of the graffiti artists were Afro-American, Porto-American, South-American, or mixed. The simple fact that they were « children of the ghetto », from The Bronx or Brooklyn, implied that they were not white people. Thus, they offered new perspectives to American society by holding up a mirror to the hegemonic culture of critics, curators, conservators, and other influences. But because they didn’t borrow from the precise themes of the « elite » culture, they were quickly enclosed in a motionless world, with frozen contours. But because the essence of graffiti was the movement, they freed themselves from those constraints.</p>
            <br />
            <p>Norman Mailer - Laureate of the Pulitzer prize - published an essay in 1974 for the glory of graffiti: Faith of graffiti. « It is like if the graffiti has arrived to invade the world: this movement which, at the origin, was a way of expression for the emigrated population, evolving in a monotone environment made of steel and bricks, of concrete and noise, broke out to save the empty walls of the city. » The tag, unique and fundamental, the essence of every style made the graffiti artist CAY 161 said in an interview with Norman Mailer ‘the name is the faith of graffiti.’</p>
            <br />
            <p>In 1980 the exhibition New York New Wave took place at the PS1 center (MoMa) in New York. Artists recognized like Warhol, were around graffiti artists or inspired painters like Basquiat. The Sam Esses studio, directed by Zephyr and Futura, had appeared for 2 months (East 75th Street in Manhattan). « Come to the graffiti studio and do what you do whenever you find yourself in front of a subway car, but here, you can take all the time you want stressless and without the danger of the court. » How would it be to paint graffiti on a large canvas instead of in the subway? Futura painted his very first canvas there, like 99% of the participants. « In the environment of the studio, we have begun to see ourselves in another light - as corny as it sounds - we were previously conditioned to see ourselves as vandals and pariahs because that was our societal niche. »</p>

            <br />
            <div  style={{alignItems: "center",textAlign: "center", paddingTop: "10px"}}>
              <img style={{width: "100%"}} src={banner3} alt=""/>
            </div>
            <br/>
            <p style={{fontWeight:"bolder"}}>Avant garde aesthetic, urban culture</p>
            <p>Galleries devoted to the promotion of artists from the graffiti movement opened:</p>
            <br />
            <p>- the Fashion Moda (3rd avenue, in The Bronx): in October 1980, the artist John Matos (aka Crash) was the curator of the exhibition « Graffiti Art Success for America (GAS)» with pieces from other artists like Futura, Lady Pink, and John Feinter.</p>
            <p>- the Fun Gallery (East Village) by Patti Astor who represented at that time Dondi White, Futura 2000, Basquiat, and Keith Haring among others.</p>

            <br />
            <p>Daze :<p style={{fontStyle: "italic"}}>« The graffiti was, back then, a language that critics wanted to learn superficially: they didn’t want to learn it fluently. »</p></p>
            <br />
            <p>We see the release of the film Wild Style by Charlie Ahearn featuring Lady Pink and Lee Quinones; the documentary Style Wars which presents many artists and highlights Kase2 and Dondi White.</p>
            <br />
            <p>Outside the exhibitions, artists meet each other, socialize, and hang out together, breaking the frontiers between the practices: the studio shared between Lee, Futura, and Zephyr, in the West Spanish district was a place where intersected the photograph Cindy Sherman, the artist Barbara Kruger, known for her powerful posters and eve young French painter from the Figuration Libre (François Boisrond, Rémi Blanchard, et Hervé Di Rosa). Very like in the artwork Honey Tell Me (1983), a collaboration between Jenny Holzer and Lady Pink. Thus, they opened the way to a new artistic and esthetic avant-garde.</p>
            <br />
            <p>Keith Haring : <p style={{fontStyle: "italic"}}>« Almost immediately after I arrived in New York in 1978, I was beginning to be interested, intrigued, and fascinated in the graffiti that I was seeing in the streets, in the subways. I was often taking the train to go to the museums or galleries, and I was beginning to see, not only the large graffiti on the outside of the subway trains but the amazing calligraphy on the inside of the cars. The calligraphy stuff reminded me of what I had learned from Chinese and Japanese calligraphy. There was also this kind of flow of consciousness - this flow of mind-hand that I had seen in Dubuffet, Mark Tobey, and Alechinsky. »</p></p>
            <br />
            <p>In December 1983, under the denomination of Post-Graffiti, the gallerist Sidney Janis, famous for his successes with pop art artists, opened his doors to these artists so that they realized legally artwork with aerosol on canvas (A-One, Jean-Michel Basquiat, Crash, Daze, Futura 2000, Keith Haring, Lady Pink, Don Leicht, Noc 167, Lee Quinones, Ramm-Ell-Zee, Kenny Scharf). The title offers a new semantic and clarifies things: even if the canvases can be similar to the artwork realized on the street, it is not graffiti writing but post-graffiti. Little to little, artists will free themselves from the codes, practices, and esthetics of graffiti writing to propose artworks that will go further than the simple transposition of this last. But the hostile or even condescending criticism incites some artists to cross the Atlantic to exhibit their work, proud and honest concerning their art. A new page of history was born...</p>
            <br />
           <p>...and some people do not mind!, graffiti writing, then the Post-graffiti was one of the most fantastic artistic revolutions of the last century, with his masters and genius, the last great art movement of the 20th century!</p>
            <br />
            <p style={{fontSize: "8px"}}>C.PdB</p>
            <p style={{fontSize: "8px"}}>© GHOST galerie - 2018 - ALL RIGHTS RESERVED | LEGAL NOTICES</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoireGraffiti;
