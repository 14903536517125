// extracted by mini-css-extract-plugin
export var Bold = "GraffitiHistoire-module--Bold---CQlJ";
export var ContactLink = "GraffitiHistoire-module--ContactLink--mK830";
export var Contactlink = "GraffitiHistoire-module--Contactlink--saE-o";
export var DevelopmentWrapper = "GraffitiHistoire-module--DevelopmentWrapper--R4Px7";
export var LegalMentionsWrapper = "GraffitiHistoire-module--LegalMentionsWrapper--07aeR";
export var Link = "GraffitiHistoire-module--Link--SYT77";
export var NameValueWrapper = "GraffitiHistoire-module--NameValueWrapper--q7w3O";
export var RowWrapper = "GraffitiHistoire-module--RowWrapper--Cr2Kv";
export var Wrapper = "GraffitiHistoire-module--Wrapper--oCDAv";
export var link = "GraffitiHistoire-module--link--ztwOB";